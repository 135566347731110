.root {
  width: 100%;
  height: 100%;
}

.wrapper {
  max-width: 500px;
  padding: 50px 16px 16px 16px;
  margin: 0 auto;
}

.img {
  margin: 0 0 30px;
  width: 200px;
}
